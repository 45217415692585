.newsOne {
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.newsOne p {
  max-width: 700px;
  font-size: 15px;
}
.newsOne img {
  width: 100%;
}
