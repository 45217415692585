.allNews {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  margin: 0 7% 0 7%;
}
.OneNews {
  min-height: 750px;
  box-shadow: 5px 5px 5px 5px#ccc;
  margin: 20px;
  cursor: pointer;
}
.OneNews img {
  width: 100%;
  object-fit: cover;
}
.texts {
  margin: 25px 25px 25px 48px;
}
.OneNews h4 {
  color: #0095db;
}

@media (max-width: 800px) {
  .allNews {
    display: grid;
    grid-template-columns: 6fr 6fr;
  }
}
@media (max-width: 600px) {
  .allNews {
    display: grid;
    grid-template-columns: 100%;
  }
}
