.partners ._L8X8r ._1eGao ._3iVQ0 img{
    object-fit: contain !important;
}

._3q7r8 {
    background-color: #fff;
}
._3lwW_, ._-LJ2W{
    display: none;
}

.partners-title{
    margin-left: 20px;
}


.grid-container{
    display: grid;  
    grid-template-columns: 25% 25% 25% 25%; 
    justify-items: center;
}

.partner-card{
    max-width: 500px;
    max-height: 400px;
    margin: 20px;
    text-align: center;
}

.partner-card img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.partner-card p{
    margin: 0;
}
.title-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.title-block span{
    width: 10%;
    height: 4px;
    margin: 0px 30px;
    background: #8ec1f5;
}

@media only screen and (max-width:550px) {
    .grid-container{
        display: grid;  
        grid-template-columns: 50% 50%; 
        justify-items: center;
    }
}