.desc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.helper {
  display: grid;
  grid-template-columns: 6fr 4fr;
  margin: 20px 15% 0 5%;
}
.forImg {
  max-width: 600px;
  margin-right: 10px;
}
.forImg img {
  width: 100%;
}
.textPart p {
  font-size: 20px;
}
.textPart ul li {
  list-style: none;
  font-size: 15px;
}
.textPart ul li::before {
  content: "☑";
  margin-right: 3px;
  color: blue;
}

@media (max-width: 800px) {
  .helper {
    display: grid;
    grid-template-columns: 100%;
    margin: 20px 5% 0 5%;
  }
}
