.header{
    /* display: grid;
    grid-template-columns: 5% 95%; */
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: black;
}

.drpoDown{
    position: relative;
}

.drpoDown>div{
    position: absolute;
    z-index: 9999;
    background: white;
    box-shadow: 5px 10px #888888;
    right: 5px;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

