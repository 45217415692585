
.list{
    border-bottom: 1px dotted #d6d6d6;
    list-style: none;
    padding: 5px;
    cursor: pointer;
}
.list:hover a{
    color: #00438a;
    font-size: 15px;
}
.list a{
    color: #565656;
    text-decoration: none;
    font-size: 14px;
}
