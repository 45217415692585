
.all {
    margin: 2% 10% 0 10%;
    background:rgb(199, 199, 206);
    border-radius: 5px;
  }
  .header{
      display:grid;
      grid-template-columns:30% 25% 25% 20%;
  }
  
  .header div{
      display: flex;
      align-items: center;
  }
  
  .nav{
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
  }
  .mapLocation{
      padding: 20px;
  }
  
  
  @media screen and (max-width:755px) {
      .header{
          grid-template-columns:50% 50%;
      }
  }
  