.main {
  position: relative;
  margin: 10px;
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: 50% 50%;
  height: calc(100vh - 30vh);
  background-repeat: no-repeat;
}
@media (max-width: 1100px) {
  .caption {
    height: calc(100vh - 35vh);
  }
}
@media (max-width: 1000px) {
  .caption {
    height: calc(100vh - 40vh);
  }
}
@media (max-width: 800px) {
  .caption {
    height: calc(100vh - 35vh);
  }
}
@media (max-width: 700px) {
  .caption {
    height: calc(100vh - 100px);
  }
}
@media (max-width: 600px) {
  .caption {
    height: calc(100vh - 700px);
  }
}
@media (max-width: 550px) {
  .caption {
    height: calc(100vh - 100px);
  }
}
@media (max-width: 450px) {
  .caption {
    height: calc(100vh - 450px);
  }
}
@media (max-width: 400px) {
  .caption {
    height: calc(100vh - 450px);
  }
}

@media (max-width: 360px) {
  .caption {
    height: calc(100vh - 450px);
  }
}
@media (max-width: 320px) {
  .caption {
    height: calc(100vh - 300px);
  }
}
@media (max-width: 280px) {
  .caption {
    height: calc(100vh - 150px);
  }
}
@media (max-width: 240px) {
  .caption {
    height: calc(100vh - 200px);
  }
}
