body {
  background-color: #f2f2f2;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 24px;
}

code {
  font-family: "Work Sans", sans-serif;
}
