.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 200px;
  height: 50px;
  object-fit: cover;
}
@media (max-width: 800px) {
  .logo img {
    width: 150px;
    height: 35px;
    object-fit: cover;
  }
}
@media (max-width: 400px) {
  .logo img {
    width: 100px;
    height: 25px;
    object-fit: cover;
  }
}
