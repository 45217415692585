.content {
  margin-left: 10%;
}
.product {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 150px 1fr;
  cursor: pointer;
  border-bottom: 1px dotted rgb(221, 221, 221);
  transition: 0.5s;
}
.product img {
  max-width: 100px;
  margin: 15px 0 15px 15px;
}
.info h2 {
  color: #00438a;
}
.info p {
  color: #333;
  font-size: 15px;
}
.pages ul{
  display: flex !important;
}

@media screen and (max-width:600px) {
  .product {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px dotted rgb(221, 221, 221);
    transition: 0.5s;
  }
}