.nav {
  display: flex;
  justify-content: space-around;
  position: absolute;
  z-index: 999;
  width: 100%;
  bottom: -65px;
}
.dv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dv .img {
  width: 300px;
  height: 150px;
  object-fit: contain;
}
.forBtn {
  position: absolute;
  bottom: 15px;
}

.forBtn button {
  background-color: #2981cf;
  padding: 15px 40px;
  border: none;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  opacity: 0.8;
}
.forBtn button:hover {
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .dv .img {
    width: 250px;
    height: 150px;
  }
}

@media screen and (max-width: 750px) {
  .dv .img {
    width: 200px;
    height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .dv .img {
    width: 150px;
    height: 130px;
  }

  .forBtn button {
    background-color: #2981cf;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    color: white;
    cursor: pointer;
  }
}

@media screen and (max-width: 500px) {
  .nav {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 999;
    width: 100%;
  }
  .dv {
    display: flex;
    margin: 20px;
    position: relative;
  }
  .dv .img {
    width: 300px;
    height: 150px;
  }
  .forBtn {
    position: absolute;
    bottom: 0;
  }
  .forBtn button {
    background-color: #2981cf;
    padding: 5px 5px;
    border: none;
    border-radius: 30px;
    color: white;
    cursor: pointer;
  }
}
