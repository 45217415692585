.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.burger {
  display: none;
}
.nav .forB {
  cursor: pointer;
  margin: 0 10px 0 10px;
}
.nav .forB a {
  text-decoration: none;
  color: #fff;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
}

.amLang{
  cursor: pointer;
  margin: 0px 10px 0px 0px;
}

.amLang a{
  text-decoration: none;
  color: #fff;
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
}

.nav .forB b {
  font-size: 15px;
  transition: 0.5s;
}
.nav .forB:hover {
  border-bottom: 3px solid #0095db;
  transition: 0.3s;
}
/* .selected {
  border-bottom: 3px solid #0095db;
} */
.flag {
  width: 25px;
  height: 15px;
  margin-right: 10px;
}
.forLang {
  display: flex;
  align-items: center;
}

@media (max-width: 894px) {
  .amLang a{
    font-size: 10px;
  }
}

@media (max-width: 815px) {
  .nav .forB,.amLang {
    display: none;
  }
  .burger {
    display: block;
  }
}
@media (max-width: 350px) {
  .flag {
    width: 21px;
    height: 11px;
    margin-right: 3px;
  }
}
