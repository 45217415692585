.title {
  color: #00438a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputArea {
  background-color: #f2f5fa;
  margin: 0 10% 0 10%;
  padding: 10px;
  border-radius: 5px;
}
.inputArea input {
  border: none;
  cursor: pointer;
  height: 30px;
  border-radius: 5px;
  transition: 0.5s;
  display: flex;
  justify-content: right;
}
.inputArea input:hover {
  box-shadow: 5px 5px #ccc;
}
.content {
  margin: 2% 10% 0 10%;
  display: grid;
  grid-template-columns: 30% 70%;
}

.content select{
  display: none;
}

@media screen and (max-width:740px) {
  .content {
    margin: 2% 10% 0 10%;
    display: grid;
    grid-template-columns: 100%;
  }
  .content ul{
    display: none;
  }
  .content select{
    display: block;
  }
}

