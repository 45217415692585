.branchPage {
  display: flex;
  justify-content: center;
}
.branches {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
}

.oneBranch {
  margin: 10px;
  padding: 5px;
  cursor: pointer;
  width: 230px;
  height: 230px;
  background-color: #fff;
  transition: 0.5s;
}



.oneBranch a{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.oneBranch img {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

a {
  text-decoration: none;
  color: #000;
}

@media (min-width: 700px) {
  .oneBranch:hover{
    margin: 10px;
    padding: 5px;
    cursor: pointer;
    width: 230px;
    height: 230px;
    background-color: #fff;
    box-shadow: 5px 5px 5px 2px #b9b7b7;
    transition: 0.5s;
  }
}

@media (max-width: 1050px) {
  .branches {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
  }
}
@media (max-width: 800px) {
  .branches {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
  }
  .oneBranch {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 700px) {
  .branches {
    display: grid;
    grid-template-columns: 6fr 6fr;
  }
  .oneBranch {
    width: 230px;
    height: 230px;
  }
}

@media (max-width: 550px) {
  .branches {
    display: grid;
    grid-template-columns: 6fr 6fr;
  }
  .oneBranch {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 460px) {
  .branches {
    display: grid;
    grid-template-columns: 100%;
  }
}
